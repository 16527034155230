<template>
  <section id="uploads">
    <header>
      <nav v-if="attachments.length > 1">
        <a :href="downloadPath" @click="checkSealed" class="btn btn-default">
          <i class="fas fa-download"></i> Download All
        </a>
      </nav>
      <div class="subtitle pull-right" v-if="loaded" style="margin-top: 3px">{{attachments.length}} {{(attachments.length == 1) ? 'File' : 'Files'}}</div>
      <h2>
        Uploads
        <i class="fas fa-shield-check" title="Sealed Response" v-if="sealed"></i>
      </h2>
    </header>
    <div v-html="form" />
    <div class="dropzone" id="dropzone" :class="{hidden: readOnly}">
      <div style="font-size: 1.25em; margin-bottom: 16px">
        <i class="fal fa-file-pdf"></i>
        <i class="fal fa-file-excel fa-lg"></i>
        <i class="fal fa-file-word"></i>
      </div>
      <div style="font-size: 1.2em">
        <strong>Drag &amp; Drop Files Here</strong>
        or
        <span class="btn btn-file btn-default">
        Browse Files
        <label for="attachment_upload_field">Attachment upload field</label>
        </span>
      </div>
    </div>
    <table class="table">
      <thead v-if="loaded && (attachments.length > 0 || uploading)">
        <td>
          <i class="fad fa-upload fa-lg fa-fw fa-beat" v-if="uploading" />
        </td>
        <td>File name</td>
        <td class="hidden-xs hidden-sm hidden-md numeric">Size</td>
        <td class="hidden-xs hidden-sm hidden-md numeric">Added</td>
        <td class="actions" v-if="!readOnly"></td>
      </thead>
      <tbody id="upload_progress" style="background: #fff" />
      <tbody style="background: #fff">
        <tr v-for="(attachment, i) in attachments" :key="attachment.id" @mouseover="hoveredRow = i" @mouseleave="hoveredRow = null">
          <td class="metadata" width=18>
            <i class="fal file-icon fa-lg fa-fw" :class="[(sealed && i == hoveredRow) ? 'fa-shield-check' : attachment.icon]"></i>
          </td>
          <td class="filename">
            <a :href="attachment.path" @click="checkSealed">{{attachment.name}}</a>
          </td>
          <td class="numeric nowrap">
            {{attachment.fileSize}}
          </td>
          <td class="numeric nowrap">
            <tooltip :text="attachment.createdAt | moment('llll')" placement="left">
              <span>{{attachment.date}}</span>
            </tooltip>
          </td>
          <td class="actions" v-if="!readOnly">
            <a class="destroy sneaky" href="#" @click.prevent="deleteAttachment(attachment)">
              <i class="far fa-trash-alt"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <script id="template-upload" type="text/x-tmpl">
      <tr id="file-{%=o.unique_id%}">
        <td class="metadata"><i class="fal fa-file fa-lg fa-fw"></i></td>
        <td>{%=o.name%}</td>
        <td colspan="3">
          <div class="progress">
          <div class="progress-bar progress-bar-info bar" style="width: 0%"></div>
          </div>
        </td>
      </tr>
    </script>
  </section>
</template>

<script>
  import { EventBus } from '../mixins/events.js'
  import axios from '../mixins/axiosDefaults'
  import { Tooltip } from 'uiv'

  export default {
    props: {
      url: {
        type: String,
        required: true
      },
      readOnly: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    components: {
      Tooltip
    },
    data: function () {
      return {
        attachments: [],
        loaded: false,
        sealed: false,
        peek: false,
        downloadPath: null,
        hoveredRow: null,
        form: null,
        uploading: false
      }
    },
    created() {
      this.loadAttachments();
    },
    mounted() {
      EventBus.$on('uploadStarted', (e) => {
        console.log('uploadStarted', e);
        this.uploading = true;
      });

      EventBus.$on('uploadCompleted', (e) => {
        console.log('uploadCompleted', e);
        this.uploading = false;
      });

      EventBus.$on('uploadSuccess', (e, data) => {
        console.log('uploadSuccess', e, data)
        this.loadAttachments();
      });

      EventBus.$on('uploadFailed', (e, data) => {
        console.log('uploadFailed', e, data)
        const fileRow = $('#file-' + data.unique_id);
        fileRow.find('.progress-bar').removeClass('progress-bar-info').addClass('progress-bar-danger').css('width', '100%');
        fileRow.find('.metadata .fa-file').removeClass('fa-file').addClass('fa-file-exclamation');
        alert(data.filename + ' failed to upload: ' + data.error_thrown + '. Contact support if you continue to have issues.');
      });

    },
    methods: {
      loadAttachments() {
        return axios.get(this.url)
          .then(response => {
            const { data } = response;
            this.attachments = data.attachments;
            if (this.form === null) this.form = data.meta.form;
            this.sealed = data.meta.sealed;
            this.peek = data.meta.peek;
            this.downloadPath = data.meta.downloadPath;
            this.loaded = true;
            this.$emit('loaded');
          })
          .catch(this.requestErrorHandler);
      },
      requestErrorHandler(error) {
        if(error.request.status === 403) {
          console.log(error)
          this.refreshPerms();
        } else if (error.request.status !== 0) {
          console.log(error.request.status);
          console.log(error.request.responseText);
          alert('Something has gone wrong. Please contact support if it continues.')
        }
      },
      checkSealed(e){
        if (this.sealed && !this.peek) {
          e.preventDefault();
          alert('This response is sealed. Uploads are only available after the due date has passed.');
        }
      },
      deleteAttachment(attachment) {
        if (confirm('Are you sure you want to delete this attachment?')) {
          axios.delete(attachment.path)
          .then(response => {
            this.loadAttachments();
          })
          .catch(this.requestErrorHandler)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .dropzone {
    border-bottom: 1px dashed #ccc;
  }

  /* FONT AWESOME GENERIC BEAT */
  .fa-beat {
    animation:fa-beat 5s ease infinite;
  }
  @keyframes fa-beat {
    0% {
      transform:scale(1);
    }
    5% {
      transform:scale(1.25);
    }
    20% {
      transform:scale(1);
    }
    30% {
      transform:scale(1);
    }
    35% {
      transform:scale(1.25);
    }
    50% {
      transform:scale(1);
    }
    55% {
      transform:scale(1.25);
    }
    70% {
      transform:scale(1);
    }
  }
</style>
