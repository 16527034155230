export const inbox = {
  props: {
    jsonPath: {
      type: String,
      required: true
    },
    emptyMessage: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      open: false,
      index: 0,
      messages: []
    }
  },
  mounted() {
    document.addEventListener("keyup", this.keyboardShortcuts);
  },
  destroyed() {
    document.removeEventListener("keyup", this.keyboardShortcuts);
  },
  computed: {
    hasNext() {
      return this.index < this.messages.length - 1;
    },
    hasPrevious() {
      return this.index > 0;
    }
  },
  methods: {
    toggleOpen(index){
      this.index = index;
      this.open = true;
    },
    next() {
      if (this.hasNext) this.toggleOpen(this.index + 1);
    },
    previous() {
      if (this.hasPrevious) this.toggleOpen(this.index - 1);
    },
    keyboardShortcuts() {
      if (this.open) {
        if (event.keyCode == 37) {
          this.previous()
        } else if (event.keyCode == 39) {
          this.next()
        }
      }
    }
  }
}
