<template>
  <div>
    <header>
      <div class="pull-right" style="font-style: italic; color: #666" v-if="messageCount > messages.length">
        Delivering Message...
      </div>
      <h2>Notifications</h2>
    </header>
    <table class="table">
      <thead>
        <tr>
          <td>Subject</td>
          <td class="numeric">Date</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(message, index) in messages">
          <td>
            <div class="truncate-ellipsis">
              <a href="javascript:void(0)" v-on:click="toggleOpen(index)">
                <tooltip :text="message.bounceDescription" v-if="message.bouncedAt"><span class="badge badge-danger">Bounced</span> </tooltip>
                {{ message.subject }}
              </a>
            </div>
          </td>
          <td style="width: 20%" class="numeric"><tooltip :text="longDate(message.date)" placement="left"><time :datetime="message.date">{{ shortDate(message.date) }}</time></tooltip></td>
        </tr>
      </tbody>
    </table>

    <p class="empty_message" v-show="messages.length == 0">{{emptyMessage}}</p>

    <modal v-model="open" id="message-viewer-modal" size="md" :footer="false" :append-to-body="true" @show="updateLocation">
      <span slot="header">
        <div class="pull-right">
          <div class="btn-group">
            <btn size="lg" @click="previous" :disabled="!hasPrevious"><i class="fa fa-arrow-left"></i></btn>
            <btn size="lg" @click="next" :disabled="!hasNext"><i class="fa fa-arrow-right"></i></btn>
          </div>
          <btn size="lg" @click="open=false"><i class="fa fa-times"></i> Close</btn>
        </div>

        <p>To: {{ message.to }} <tooltip :text="message.bounceDescription" v-if="message.bouncedAt"><span class="badge badge-danger">Bounced</span></tooltip><br/>
           Date: {{ longDate(message.date) }}
           <span v-if="message.replyTo"><br/>Reply-To: {{ message.replyTo }}</span>
           <span v-if="message.clickedAt"><br/>
              Last Clicked: {{ longDate(message.clickedAt) }} <tooltip text="User last clicked a link within the email at this time"><i class="fa fa-question-circle"></i></tooltip></span>
        </p>
        <h2>{{ message.subject }}</h2>
      </span>
      <div style="background-color: #f0f0f0; width: 100%; height: 450px;">
        <iframe width="100%" height="100%" frameborder="0" ref="frame" @load="iframe.loaded = true" v-show="iframe.loaded"></iframe>
      </div>
    </modal>
  </div>
</template>

<script>
  import axios from '../mixins/axiosDefaults'
  import { EventBus } from '../mixins/events.js'
  import { inbox } from '../mixins/inbox'
  import { Btn, Modal, Tooltip } from 'uiv'

  export default {
    components: { Btn, Modal, Tooltip },
    mixins: [inbox],
    data: function () {
      return {
        src: '/',
        messages: [],
        iframe: {
          src: '',
          loaded: false
        },
        message: {},
        messageCount: 0
      }
    },
    created() {
      this.fetchData();
      EventBus.$on('notificationSent', () => {
        this.messageCount++
        this.fetchData();
      });
    },
    methods: {
      longDate(date) {
        return moment(date).format('LLL');
      },
      shortDate(date) {
        let mDate = moment(date);
        let iscurrentDate = mDate.isSame(new Date(), "day");
        return mDate.format((iscurrentDate) ? 'h:mm a' : 'L');
      },
      fetchData() {
        axios.get(this.jsonPath).then(this.loadData)
      },
      loadData(response) {
        this.messages = response.data.messages;
        if (this.messageCount > this.messages.length) {
          setTimeout(this.fetchData, 10000)
        } else {
          this.messageCount = this.messages.length;
        }
      },
      toggleOpen(index){
        this.iframe.loaded = false;
        this.index = index;
        this.message = this.messages[index];
        if (this.open) {
          this.updateLocation()
        } else {
          // Use modal @open event to updateLocation to prevent race condition for visibility
          this.open = true;
        }
      },
      updateLocation() {
        // NOTE: just updating the src was hijacking the browser history
        this.$refs.frame.contentWindow.location.replace(this.jsonPath + '/' + this.message.id)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .modal-header {
    h2 {
      clear: right;
    }
  }
</style>
